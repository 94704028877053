
import { Vue, Component } from 'vue-property-decorator'
import { apiGetPay, apiSetPay } from '@/api/setting'

@Component
export default class SettingRecord extends Vue {
    /** S Data **/
    // 表单数据
    form: any = {
      app_id: '',
      app_secret: '',
      ali_app_id: '',
      ali_private_key: '',
      ali_public_key: '',
      mch_id: '',
      key: ''
    };

    // 表单验证
    rules: object = {

    };
    /** E Data **/

    /** S Methods **/
    // 初始化表单数据
    async initFormData (): Promise<void> {
      const res: any = await apiGetPay({})
      // for (const key in res) {
      //     this.form[key] = res[key];
      // }
      Object.keys(res).map((key) => {
        this.$set(this.form, key, res[key])
      })
    }

    // 提交表单
    onSubmitFrom (formName: string) {
      const refs = this.$refs[formName] as HTMLFormElement

      refs.validate(async (valid: boolean): Promise<void> => {
        if (!valid) return
        const loading = this.$loading({ text: '保存中' })

        await apiSetPay({
          ...this.form
        }).finally(() => {
          loading.close()
        })
        this.$store.dispatch('getConfig')
      })
    }
    /** E Methods **/

    /** S Life Cycle **/
    created () {
      this.initFormData()
    }
    /** E Life Cycle **/
}
